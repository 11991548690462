<template>
<div style="height: auto;background-color: #fff;border: 10px solid #EDEFF2;">
  <a-spin :spinning="temSpin">
  <div class="CCE">
    <div style="margin-top: 6px;width: 75px">会议分类：</div>
    <div>
      <a-button
          :class="{ liBackground:provincesStyle === 0,textAlign:'center',color:variables.topNameColor}"
          size="small"
          @click="provinces(0)"
          style="border: none;box-shadow: none;">全部</a-button>
      <a-button v-for="(item,index) in liveClassifyList" :key="item.id"
                :style="{margin: '5px',border: 'none',boxShadow: 'none',color:variables.topNameColor}"
                size="small"
                :class="{ liBackground:provincesStyle === index+1,textAlign:'center'}"
                id="provincesBtn"
                @click="provinces(index+1,item.id)">{{item.title}}</a-button>
    </div>
  </div>
  <div class="live-top">
    <div :style="{display: 'flex',justifyContent: 'space-between',width: variables.space_div,margin: 'auto'}">
      <div class="live-top-input">
        <div :style="{color:variables.topTitleColor}">会议名称：</div>
        <a-input
            v-model="titleData"
            class="input"
            placeholder="会议名称关键词" />
      </div>
      <div class="live-top-input">
        <div :style="{color:variables.topTitleColor,width:'70px'}">会议日期：</div>
        <a-range-picker
            style="width: 250px"
            v-model="year"
            @change="onChange"
            :allowClear="false"
            @ok="timeChangeOk">
<!--          <template slot="renderExtraFooter">-->
<!--            extra footer-->
<!--          </template>-->
        </a-range-picker>
        <!--        <a-range-picker @change="onChange" />-->
      </div>
      <div class="live-top-input">
        <div :style="{color:variables.topTitleColor,width:'70px'}">会议状态：</div>
        <a-radio-group v-model="liveStatus" button-style="solid" >
          <a-radio-button value="0" @click="radioClick('0')">
            未开始
          </a-radio-button>
          <a-radio-button value="1" @click="radioClick('1')">
            直播中
          </a-radio-button>
          <a-radio-button value="2" @click="radioClick('2')">
            已结束
          </a-radio-button>
        </a-radio-group>
      </div>
      <div class="live-top-input">
        <a-button
            @click="seachBtn"
            type="primary">查询</a-button>
        <a-button
            @click="resetBtn"
            style="margin-left: 10px">重置</a-button>
      </div>
    </div>
  </div>
  <div class="addBtn">
    <div class="titleAdd">
      <div class="title">会议列表</div>
      <a-button @click="addTopBtn" type="primary" style="width: 100px;margin-top: 9px"> + 新建会议</a-button>
    </div>
  </div>
    <div v-if="liveList.length === 0"><NullDataPrompt message="您还没有会议呢 ~"/></div>
  <div class="topCard" v-for="list in liveList">
   <div style="width: 97%;margin: auto">
     <div style="margin-left: 1%;margin-bottom: 1%">
       <a-tag :color="list.statusSeries === 0 ? 'blue':list.statusSeries === 1 ?'red': list.statusSeries === 2 ? 'green':'' ">
         {{ list.statusSeries === 0 ? '未开始':list.statusSeries === 1 ?'进行中': list.statusSeries === 2 ? '已结束':''  }}
       </a-tag>
       <span style="font-weight: bold;font-size: 16px">{{list.title}}</span>
     </div>
     <a-row>
       <a-col :span="6" @click="openAddRooms(list.id,list)">
         <div class="card add" >
           +
         </div>
       </a-col>
       <a-col
           :span="6"
           style="margin-bottom: 15px"
           v-for="card in list.dataList">
         <div class="card">
           <div class="tagImg" style="position: relative">
             <a-tag style="position: absolute;margin: 10px;color: white"
                    :color="card.streamStatus === 0 ? '#2db7f5' : card.streamStatus === 1 ? '#FFBA69' : card.streamStatus === 2 ? '#cccccc':''">
               {{card.streamStatus === 1?'直播中':card.streamStatus === 0?'未开始':card.streamStatus === 2?'已结束':''}}
             </a-tag>
             <img class="img" :src="card.coverUrl">
           </div>

           <div style="margin: 7px">
             <h3 class="titleSty">{{card.title}}</h3>
             <span style="color: #a0a2a3;">{{card.live_start_time}}</span>
           </div>
           <div class="btn">
             <div class="fontSty" @click="linkOpen(card)">链接</div>
             <div>|</div>
             <div class="fontSty" @click="functionSet(card)">功能</div>
             <div>|</div>
             <div class="fontSty" @click="restBtn(card)">设置</div>
<!--             <div class="fontSty">-->
<!--               <a-dropdown>-->
<!--                 <a style="color: #b4b3b3" class="ant-dropdown-link" @click="e => e.preventDefault()">-->
<!--                   更多 <a-icon type="down" />-->
<!--                 </a>-->
<!--                 <a-menu slot="overlay">-->
<!--                   <a-menu-item @click="restBtn(card)">-->
<!--                     <a href="javascript:;">编辑</a>-->
<!--                   </a-menu-item>-->
<!--                 </a-menu>-->
<!--               </a-dropdown>-->
<!--             </div>-->
           </div>
         </div>
       </a-col>
     </a-row>
     <div style="margin-bottom: 10px;display: flex;justify-content: space-between;">
       <div>
         <span class="btns"
               @click="liveRest(list)"
               v-show="list.statusSeries !== 2">编辑</span>
         <span class="btns" v-show="list.statusSeries !== 2">|</span>
         <span class="btns" @click="openCatalogue(list.meetingCode)">目录</span>
         <span class="btns" v-show="list.statusSeries === 0">|</span>
         <span class="btns" v-show="list.statusSeries === 0">
           <a-popconfirm
               title="是否确认删除?"
               ok-text="是"
               cancel-text="否"
               @confirm="notStartDel(list.meetingCode)"
           ><a href="#">删除</a>
           </a-popconfirm>
       </span>
         <span class="btns" >|</span>
         <span class="btns" @click="openingBtn(list)">开场广告</span>
       </div>
       <div>
         共{{list.meetingNumber}}期，已完成{{list.meetingOverNumber}}期，剩余{{list.meetingNumber - list.meetingOverNumber}}期
       </div>
     </div>
   </div>
  </div>
<!------------------目录----------------------->
  <a-drawer
      :get-container="false"
      @close="catalogueCancel"
      :width="1000"
      :body-style="{ paddingBottom: '80px' }"
      :visible="catalogueVisible"
      title="目录">
    <div class="live-top-drawer">
      <div class="live-top-input">
          <div style="width: 70px">会议名称:</div>
          <a-input
              @pressEnter="seachDrawer"
              v-model="titleDrawer"
              class="input"
              placeholder="会议名称关键词" />
        </div>
      <div class="live-top-input">
          <div style="width: 70px">会议状态: </div>
        <a-radio-group v-model="statusDrawer" button-style="solid" >
          <a-radio-button value="0" @click="drawerClick('0')">
            未开始
          </a-radio-button>
          <a-radio-button value="1" @click="drawerClick('1')">
            直播中
          </a-radio-button>
          <a-radio-button value="2" @click="drawerClick('2')">
            已结束
          </a-radio-button>
        </a-radio-group>
        </div>
      <div>
          <a-button
              @click="seachDrawer"
              type="primary">查询</a-button>
          <a-button
              @click="resetDrawer"
              style="margin-left: 10px">重置</a-button>
        </div>
    </div>
    <a-row style="width: 950px;margin: auto">
        <a-col
            :span="8"
            style="margin-bottom: 15px"
            v-for="card in drawerList">
          <div class="card">
            <div class="tagImg" style="position: relative">
              <a-tag style="position: absolute;margin: 10px;color: white"
                     :color="card.streamStatus === 0 ? '#2db7f5' : card.streamStatus === 1 ? '#FFBA69' : card.streamStatus === 2 ? '#cccccc':''">
                {{card.streamStatus === 1?'直播中':card.streamStatus === 0?'未开始':card.streamStatus === 2?'已结束':''}}
              </a-tag>
              <img class="img" :src="card.coverUrl">
            </div>

            <div style="margin: 7px">
              <h3 class="titleSty">{{card.title}}</h3>
              <span style="color: #a0a2a3;">{{card.live_start_time}}</span>
            </div>
            <div class="btn">
              <div class="fontSty" @click="linkOpen(card)">链接</div>
              <div>|</div>
              <div class="fontSty" @click="functionSet(card)">功能</div>
              <div>|</div>
              <div class="fontSty" @click="restBtn(card)">设置</div>
<!--              <div class="fontSty">-->
<!--                <a-dropdown>-->
<!--                  <a style="color: #b4b3b3" class="ant-dropdown-link" @click="e => e.preventDefault()">-->
<!--                    更多 <a-icon type="down" />-->
<!--                  </a>-->
<!--                  <a-menu slot="overlay">-->
<!--                    <a-menu-item @click="restBtn(card)">-->
<!--                      <a href="javascript:;">编辑</a>-->
<!--                    </a-menu-item>-->
<!--                  </a-menu>-->
<!--                </a-dropdown>-->
<!--              </div>-->
            </div>
          </div>
        </a-col>
    </a-row>
    <div style="width: 100%;" v-show="drawerList.length">
      <div style="display: flex;justify-content: center">
        <a-pagination
            show-quick-jumper
            :pageSize="pageSizeModel"
            :current="pageNoModel"
            :total="totalModel"
            @change="pageChange" />
      </div>
    </div>
    <div :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }">
      <a-button  @click="catalogueCancel">
        取消
      </a-button>
      <a-button style="margin-left: 10px" type="primary" @click="catalogueOk">
        确认
      </a-button>
    </div>
  </a-drawer>
<!------------------新增修改-------------------->
  <a-drawer
      :get-container="false"
        @close="addCancel"
        :width="660"
        :body-style="{ paddingBottom: '80px' }"
        :visible="addVisible"
        :title="addTitle">
    <a-form-model
        ref="addRef"
        :model="addForm"
        :rules="addRules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
    >
      <a-form-model-item  prop="name" label="会议名称">
        <a-input v-model="addForm.name" style="width: 350px;" placeholder="请输入"/>
      </a-form-model-item>
      <a-form-model-item  prop="columId" label="会议分类" style="width: 600px">
        <a-select
            show-search
            option-filter-prop="children"
            style="width: 300px;float: left"
            class="input"
            v-model="addForm.columId"
            placeholder="请选择">
          <a-select-option
              v-for="lives in liveClassifyList"
              :value="lives.id">
            {{lives.title}}
          </a-select-option>
        </a-select>
        <!--              <div>-->
        <a-icon @click="liveClassifyRow" class="iconStY" type="redo" />
        <a-icon @click="loadList" class="iconStY" type="plus-square" />
        <!--              </div>-->
      </a-form-model-item>
      <a-form-model-item  prop="periods" label="会议期数">
<!--        <div style="display: flex">-->
        <a-input-number
            placeholder="请输入"
            style="border-radius: 0;border-right: none;width: 310px;"
            id="inputNumber"
            v-model="addForm.periods"
            :min="1"/>
<!--        <div class="qi">期</div>-->
<!--        </div>-->
        <a-button class="qiBtn">期</a-button>
      </a-form-model-item>
    </a-form-model>
    <div :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }">
      <a-button  @click="addCancel">
        取消
      </a-button>
      <a-button style="margin-left: 10px" type="primary" @click="addOk">
        确认
      </a-button>
    </div>
  </a-drawer>
<!------------------新增直播间------------------>
  <a-drawer
      @close="add_cancel"
      :width="660"
      :body-style="{ paddingBottom: '80px' }"
      :visible="add_visible"
      :title="drawerTitle">
    <a-form-model
        ref="content_list_Form_add"
        :model="add_Form"
        :rules="addFormRules"
        :label-col="labelColAdd"
        :wrapper-col="wrapperCol"
    >
      <a-form-model-item  prop="name" label="标题">
        <a-input v-model="add_Form.name" style="width: 300px;" placeholder="请输入"/>
      </a-form-model-item>

      <a-form-model-item  prop="time" label="会议时间">
        <a-input v-model="add_Form.time" style="width: 300px;" placeholder="例：6月21日 08:30～11:00"/>
      </a-form-model-item>

      <a-form-model-item  prop="startTime" label="开始时间">
        <a-date-picker
            style="width: 300px"
            placeholder="请选择"
            @change="StartChange"
            v-model="add_Form.startTime"
            format="YYYY-MM-DD HH:mm:ss"
            :show-time="{ defaultValue: moment('00:00:00', 'HH:mm:ss') }"
        />
      </a-form-model-item>

      <a-form-model-item label="详情封面图" prop="relayImg">
        <div style="position: relative;width: 320px;">
          <div class="upImgModel" @click="openImgModel('relayImg')" >
            <div class="content" v-if="!add_Form.relayImg">
              <div class="addModel">+</div>
              <div>
                上传
              </div>
            </div>
            <div>
              <img style="width: 310px;border-radius: 10px;margin-top: 1%;margin-bottom: 1%;margin-left: 3px"
                   :src="add_Form.relayImg" alt="">
            </div>
          </div>
          <a-icon v-if="add_Form.relayImg"
                  @click="()=>{add_Form.relayImg = ''}"
                  class="icon" type="close-circle"/>
        </div>
<!--        <div class="surfacePlot" style="position: relative">-->
<!--          <div style="position: absolute;width: 100%;text-align: center" v-show="!add_Form.relayImg">-->
<!--            <div style="font-size: 40px;color: #929292;">+</div>-->
<!--            <div style="font-size: 20px;color: #929292">上传</div>-->
<!--          </div>-->
<!--          <input-->
<!--              id="surfacePlot"-->
<!--              ref="surfacePlot"-->
<!--              style="width: 100%;-->
<!--                  height: 100%;opacity: 0;-->
<!--                  z-index: 999999;-->
<!--                  position: absolute;top: 0"-->
<!--              accept="image/*"-->
<!--              type="file"-->
<!--              @change="surfacePlotBtn" />-->
<!--          <div class="uploadImg_img">-->
<!--            <img-->
<!--                v-if="add_Form.relayImg"-->
<!--                alt=""-->
<!--                style="width: 98%;height:98%;border-radius: 5px;margin: auto;margin-top: 2px;background-color: #fff;"-->
<!--                :src="add_Form.relayImg"-->
<!--            />-->
<!--          </div>-->
<!--          <div style="position: absolute;top: -8px;right: 10px;z-index: 99999999">-->
<!--            <a-icon v-show="add_Form.relayImg" @click="()=>{add_Form.relayImg = ''}" style="color: #f82c2c;cursor: pointer;" type="close-circle" />-->
<!--          </div>-->
<!--        </div>-->
        <div style="color: #a1a1a1">注:尺寸 640*360，大小不超过500kb</div>
      </a-form-model-item>

      <a-form-model-item label="介绍海报">
        <div style="position: relative;width: 320px;">
          <div class="upImgModel" @click="openImgModel('homeCoverImage')" >
            <div class="content" v-if="!add_Form.homeCoverImage">
              <div class="addModel">+</div>
              <div>
                上传
              </div>
            </div>
            <div>
              <img style="width: 310px;border-radius: 10px;margin-top: 1%;margin-bottom: 1%;margin-left: 3px"
                   :src="add_Form.homeCoverImage" alt="">
            </div>
          </div>
          <a-icon v-if="add_Form.homeCoverImage"
                  @click="()=>{this.add_Form.homeCoverImage = ''}"
                  class="icon" type="close-circle"/>
        </div>
<!--        <div class="bgImg" style="position: relative">-->
<!--          <div style="position: absolute;width: 100%;text-align: center" v-show="!add_Form.homeCoverImage">-->
<!--            <div style="font-size: 40px;color: #929292;margin-bottom: 10px;margin-top: 30px">+</div>-->
<!--            <div style="font-size: 20px;color: #929292">上传</div>-->
<!--          </div>-->
<!--          <input-->
<!--              id="add_back_id"-->
<!--              ref="add_back_ref"-->
<!--              style="width: 100%;height: 100%;-->
<!--                  z-index: 999999;-->
<!--                  opacity: 0;-->
<!--                  position: absolute;top: 0"-->
<!--              accept="image/*"-->
<!--              type="file"-->
<!--              @change="add_back" />-->
<!--          <div class="uploadImg_img">-->
<!--            <img-->
<!--                v-if="add_Form.homeCoverImage"-->
<!--                alt=""-->
<!--                style="width: 98%;height:auto;border-radius: 5px;margin: auto;margin-top: 2px;background-color: #fff;"-->
<!--                :src="add_Form.homeCoverImage"-->
<!--            />-->
<!--          </div>-->
<!--          <div style="position: absolute;top: -8px;right: 10px;z-index: 99999999">-->
<!--            <a-icon v-show="add_Form.homeCoverImage" @click="()=>{add_Form.homeCoverImage = ''}" style="color: #f82c2c;cursor: pointer;" type="close-circle" />-->
<!--          </div>-->
<!--        </div>-->
        <div style="color: #a1a1a1">注:尺寸宽640，大小不超过1M</div>
      </a-form-model-item>
    </a-form-model>
    <div :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }">
      <a-button  @click="add_cancel">
        取消
      </a-button>
      <a-button style="margin-left: 10px" type="primary" @click="add_content_list_Ok">
        确认
      </a-button>
    </div>
  </a-drawer>
<!------------------新增分类对话框--------------->
  <a-modal
      title="新增"
      :visible="visibleClass"
      @ok="handleOk"
      @cancel="handleCancel"
      style="width:200px;height: 130px;"
  >
    <a-form-model :model="form"  :rules="addRule" ref="ruleForm_add" style="display:flex">
      <a-form-model-item label="分类标题" prop="name" style="display:flex">
        <a-input  placeholder="请输入"   v-model="form.name"  style="display:flex;margin-top:5px;width:220px"/>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
<!------------------开场广告-------------------->
  <a-drawer
        @close="opening_cancel"
        :width="660"
        :body-style="{ paddingBottom: '80px' }"
        :visible="opening_visible"
        title="开场广告">
    <a-form-model
        ref="promotion_ruleForm"
        :model="promotion_form"
        :rules="promotion_rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
    >
      <a-form-model-item  label="开场图">
        <a-switch
            v-model="promotion_form.isBeginImage === 1?true:false"
            checked-children="开"
            un-checked-children="关"
            @change="switchChange(promotion_form.isBeginImage === 0?1:0)"
            default-checked />
      </a-form-model-item>
      <a-form-model-item prop="beginImageDetail" label="选择图片">
        <div style="position: relative;width: 320px;">
          <div class="upImgModel" @click="openImgModel('beginImageDetail')" >
            <div class="content" v-if="!promotion_form.beginImageDetail">
              <div class="addModel">+</div>
              <div>
                上传
              </div>
            </div>
            <div>
              <img style="width: 310px;border-radius: 10px;margin-top: 1%;margin-bottom: 1%;margin-left: 3px" :src="promotion_form.beginImageDetail" alt="">
            </div>
          </div>
          <a-icon v-if="promotion_form.beginImageDetail"
                  @click="()=>{promotion_form.beginImageDetail = ''}"
                 class="icon" type="close-circle"/>
        </div>
        <div style="color: #a1a1a1">注:尺寸宽640，大小不超过1M</div>
      </a-form-model-item>
      <a-form-model-item label="时长" prop="beginImageTime">
        <a-input-number v-model.number="promotion_form.beginImageTime" placeholder="建议不超过5秒" style="width: 275px;border-radius: 0"/>
        <a-button style="background-color: #F5F5F5;border-radius: 0;position: absolute;top: -7px;border-left: none">
          秒
        </a-button>
      </a-form-model-item>
    </a-form-model>
    <div :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }">
      <a-button  @click="opening_cancel">
        取消
      </a-button>
      <a-button style="margin-left: 10px" type="primary" @click="opening_Ok">
        确认
      </a-button>
    </div>
    </a-drawer>
<!------------------链接----------------------->
    <LinkPopup></LinkPopup>
<!------------------图片对话框----------------------->
    <pictureDialog
        :visible="visible"
        v-on:closeMain="closeMain"
        v-on:checkList="checkList">
    </pictureDialog>
  </a-spin>
</div>
</template>

<script>
import variables from '@/assets/base.scss'
import {liveClassify} from "@/service/MedicalConference";
import {
  delLive,
  getLiveRoom,
  liveMettingSpecials,
  liveMettingSpecialsPost,
  liveMettingSpecialsPut,
  postLiveRoom,
  putLiveRoom, updMeetingBeginImg
} from "@/service/liveManage";
import moment from "moment";
import {update} from "@/utils/update";
import {newPutSetLive} from "@/service/liveMeet_api";
import LinkPopup from "@/components/LiveRoomFunModule/LinkPopup";
import {AddManagement} from "@/service/columnmanageapi";
import PictureDialog from "@/components/Material/pictureDialog";
import NullDataPrompt from "@/components/NullDataPrompt";
export default {
name: "LiveConferenceSeries",
  components: {PictureDialog, LinkPopup,NullDataPrompt},
  data(){
  return{
    variables,
    visible:false,
    openModelTypr:null,
    picList:[],
    listMeetCode:null,
    promotion_form:{
      isBeginImage:0,
      beginImageDetail:'',
      beginImageTime:null,
    },
    promotion_rules: {
      beginImageDetail: [{ required: true, message: '请上传', trigger: 'blur' }],
      beginImageTime: [{ required: true, message: '请输入', trigger: 'blur' }],
    },
    childData:false,
    provincesStyle:0,
    liveClassifyList:[],
    columId:null,
    titleData:'',
    pageNo:1,
    liveStatus:'',
    status0:1,
    status1:1,
    status_1:1,
    year:[undefined,undefined],
    liveList:[],

    catalogueVisible:false,
    roomId:null,
    titleDrawer:'',
    statusDrawer:'',
    drawer0:1,
    drawer1:1,
    drawer2:1,
    drawerList:[],
    pageSizeModel:6,
    pageNoModel:1,
    totalModel:0,
    visibleClass:false,
    addVisible:false,
    addTitle:'',
    listCode:null,
    addForm:{

    },
    addRules:{
      name: [{ required: true, message: '请输入', trigger: 'blur' }],
      periods: [{ required: true, message: '请输入', trigger: 'blur' }],
      columId: [{ required: true, message: '请选择', trigger: 'change' }],
    },
    labelColAdd: { span: 6},
    labelCol: { span: 4},
    wrapperCol: { span: 14 },
    form:{},
    addRule:{
      name: [{ required: true, message: '请输入名称', trigger: 'blur' }]
    },
    add_visible:false,
    add_Form:{
      name:'',
      time:'',
      startTime:'',
      columId:undefined,
      homeCoverImage:'',
      relayImg:'',
    },
    addFormRules:{
      name: [{ required: true, message: '请输入', trigger: 'blur' }],
      time: [{ required: true, message: '请选择', trigger: 'change' }],
      startTime: [{ required: true, message: '请选择', trigger: 'change' }],
      // columId: [{ required: true, message: '请选择', trigger: 'change' }],
      relayImg: [{ required: true, message: '请选择', trigger: 'blur' }],
    },
    drawerTitle:'',
    liveId:null,
    liveRoomId:null,
    temSpin:false,
    opening_visible:false,
  }
  },
  created() {
    console.log(this.provincesStyle)
    this.getLiveList()
    this.liveClassifyRow()
  },
  methods:{
    async add_back() {
      let inputDOM = this.$refs.add_back_ref.files[0];
      //判断图片大小
      if (inputDOM.size < 1048576) {
        const image = await update(inputDOM)
        if (image.code === 0) {
          this.add_Form.homeCoverImage = image.data.url
          this.$message.success("上传成功")
        } else {
          this.$message.warning("上传失败")
        }
      } else {
        this.$message.warning("请上传小于1M的图片！")
      }
      document.getElementById('add_back_id').value = null
    },
    async surfacePlotBtn() {
      let inputDOM = this.$refs.surfacePlot.files[0];
      //判断图片大小
      if (inputDOM.size < 512000) {
        const image = await update(inputDOM)
        if (image.code === 0) {
          this.add_Form.relayImg = image.data.url
          this.$message.success("上传成功")
        } else {
          this.$message.warning("上传失败")
        }

      } else {
        this.$message.warning("请上传小于500Kb的图片！")
      }
      document.getElementById('surfacePlot').value = null
    },
    moment,
    StartChange(date, dateString){
      this.add_Form.startTime = dateString;
    },
    add_cancel(){
      //  关闭对话框
      this.add_visible = false;
      this.add_Form = {
        name:'',
        time:'',
        startTime:'',
        columId:undefined,
        homeCoverImage:'',
        relayImg:'',
      };
      this.listId = '';
      this.$refs.content_list_Form_add.resetFields();
    },
    async add_content_list_Ok() {
      this.$refs.content_list_Form_add.validate(valid => {
        if (valid) {
          let form = this.add_Form
          if(this.liveRoomId){
            const dataRest = {
              "title":form.name,
              "showTime":form.time,
              "live_start_time":form.startTime,
              "coverUrl":form.relayImg,
              "descImageUrl":form.homeCoverImage,
              "id":this.liveRoomId
            }
            this.putLiveRooms(dataRest);
          }else {
            const data = {
              "title":form.name,
              "showTime":form.time,
              "live_start_time":form.startTime,
              "coverUrl":form.relayImg,
              "descImageUrl":form.homeCoverImage,
              "liveMettingId":this.liveId
            }
            this.postLiveRooms(data);
          }
          this.add_cancel();
        } else {
          return false;
        }
      });
    },

    //新增直播间列表
    async postLiveRooms(data) {
      const response = await postLiveRoom(data)
      if(response.code === 0){
        this.$message.success("新增成功~")
        await this.getLiveList();
      }else {
        this.$message.warning(response.message)
      }
    },
    //修改直播间列表
    async putLiveRooms(data) {
      const response = await putLiveRoom(data)
      if(response.code === 0){
        this.$message.success("修改成功~");
        await this.getRooms(this.roomId);
        await this.getLiveList();
      }else {
        this.$message.warning(response.message);
      }
    },
    //新增直播会议列表
    async postLive(data) {
      const response = await liveMettingSpecialsPost(data)
      if(response.code === 0){
        this.$message.success("新增成功~")
        await this.getLiveList();
      }else {
        this.$message.warning(response.message)
      }
    },

    //修改直播会议列表
    async putLive(listMeetingCode,data) {
      const response = await liveMettingSpecialsPut(listMeetingCode,data)
      if(response.code === 0){
        this.$message.success("修改成功~")
        await this.getLiveList();
      }else {
        this.$message.warning(response.message)
      }
    },
    //获取会议列表
    async getLiveList() {
      this.temSpin = true;
      let data = {
        type:'SERIES',
        title:this.titleData,
        columnId:this.columId,
        statusSeries:this.liveStatus,
        starTime:this.year[0],
        endTime:this.year[1],
        pageNo:this.page_no,
        pageSize:this.page_size,
      }
      const response = await liveMettingSpecials(data)
      if(response.code === 0){
        //获取系列会下的直播间列表
        this.liveList = response.data.rows
        for (const i in response.data.rows){
          this.getLiveRooms(response.data.rows[i].meetingCode, i)
        }

        this.total = response.data.count
      }else {
        this.$message.warning(response.message)
      }
      this.temSpin = false;
    },
    //获取直播间列表
    async getLiveRooms(meetingCode,index) {
      let data = {
        page_no:1,
        page_size:3
      }
      const response = await getLiveRoom(meetingCode,data)
      if(response.code === 0){
        this.$set(this.liveList[index],'dataList',response.data.rows)
      }
    },
    addCancel(){
      this.addVisible = false;
      this.addForm = {}
    },
    addOk(){
      this.$refs.addRef.validate(valid => {
        if (valid) {
          let form = this.addForm;
          if(this.listCode){
            //修改
            const dataRest = {
              "title":form.name,
              "columnId":form.columId,//会议分类id
              "meetingNumber":form.periods//会议期数
            }
            this.putLive(this.listCode,dataRest)
          }else {
            //新增
            const data = {
              "title":form.name,
              "type":"SERIES",//写死
              "columnId":form.columId,//会议分类id
              "meetingNumber":form.periods//会议期数
            }
            this.postLive(data)
          }
          this.addCancel()
        } else {
          return false;
        }
      });
    },
    handleOk(){
      this.$refs.ruleForm_add.validate(valid=> {
        if (valid) {
          let data = {
            title: this.form.name,
            type:'meeting'
          }
          this.postClass(data)
          this.form.name = '';
          this.visibleClass = false;
        }
      })
    },
    async postClass(data) {
      const response = await AddManagement(data)
      if (response.code === 200) {
        this.$message.success("新增成功！")
      } else {
        this.$message.warning(response.message)
      }
    },
    handleCancel(){
      this.visibleClass = false;
    },
    loadList(){
      this.visibleClass = true;
    },
    seachBtn(){
      this.getLiveList();
    },
    resetBtn(){

    },
    linkOpen(row){
      if(row.targetUrl){
        this.$store.dispatch("isShowLink", true);
        let data = {
          //前端观看链接
          targetUrl:row.targetUrl ? row.targetUrl : '',
          //服务器链接
          obsPushIp:row.obsPushIp ? row.obsPushIp : '',
          //串流密钥链接
          obsPushName:row.obsPushName ? row.obsPushName : '',
          //rtmp链接
          pullRtmpIp:row.pullRtmpIp ? row.pullRtmpIp : '',
          //flv链接
          pullFlvIp:row.pullFlvIp ? row.pullFlvIp : '',
          //m3u8链接
          pullM3u8Ip:row.pullM3u8Ip ? row.pullM3u8Ip : '',
        }
        this.$store.dispatch("linkList", data);
      }else {
        this.$message.info("当前链接为空！")
      }
    },
    functionSet(row){
      this.$router.push({path:'/liveRoom',query:{id:row.id,meetingCode:row.meetingCode,type:'SERIES'}})
      this.$store.dispatch('isShowNav',false);
    },
    restBtn(row){
      console.log(row)
      this.liveRoomId = row.id
      this.drawerTitle = '编辑';
      this.listMeetingCode = row.meetingCode; //会议编码
      this.add_Form = {
        name:row.title,
        time:row.showTime,
        startTime:row.live_start_time,
        homeCoverImage:row.descImageUrl,
        relayImg:row.coverUrl,
      }
      this.add_visible = true;
    },
    openingBtn(row){
      this.listMeetCode = row.meetingCode;
      this.promotion_form = {
        isBeginImage:row.isBeginImage,
        beginImageDetail:row.beginImageDetail,
        beginImageTime:row.beginImageTime,
      }
      this.opening_visible = true;
    },
    opening_cancel(){
      this.opening_visible = false;
    },
    openAddRooms(id,list){
      //判断期数已满不可在新增直播间
      if(list.meetingNumber - list.meetingOverNumber > 0){
        this.liveRoomId = null;
        this.drawerTitle = '新增';
        this.listMeetingCode = '';
        this.liveId = id;
        this.add_visible = true;
      }else {
        this.$message.info("会议期数已满！")
      }

    },
    addTopBtn(){
      this.addVisible = true;
      this.addTitle = '新增';
      this.listCode = null;
    },
    liveRest(row){
      this.listCode = row.meetingCode;
      this.addVisible = true;
      this.addTitle = '修改';
      this.addForm = {
        name:row.title,
        columId:row.columnId,
        periods:row.meetingNumber,
      }
    },
    radioChange(){
      this.pageNo=1;
      // this.getLiveList();
    },
    radioClick(val){
      if(val === '0'){
        if(this.status0 === 1){
          this.liveStatus = val;
          this.status0++;
        }else {
          this.liveStatus = '';
          this.status0 = 1;
        }
        this.status1 = 1;
        this.status_1 = 1;
      }else if(val === '1'){
        if(this.status1 === 1){
          this.liveStatus = val;
          this.status1++;
        }else {
          this.liveStatus = '';
          this.status1 = 1;
        }
        this.status0 = 1;
        this.status_1 = 1;
      }else if(val === '2'){
        if(this.status_1 === 1){
          this.liveStatus = val;
          this.status_1++;
        }else {
          this.liveStatus = '';
          this.status_1 = 1;
        }
        this.status1 = 1;
        this.status0 = 1;
      }
      this.page_no = 1;
      this.getLiveList();
    },
    timeChangeOk(){
      // this.getLiveList()
    },
    onChange(date, dateString){
      this.year = dateString
    },
    provinces(index,id){
      this.provincesStyle = index;
      this.columId = id;
    },
    async liveClassifyRow() {
      let data = {
        pageNo:1,
        pageSize: 9999999,
        type:'meeting'
      }
      const response = await liveClassify(data)
      if(response.code === 0){
        this.liveClassifyList = response.data
      }else {
        this.$message.warning(response.message)
      }
    },
    async notStartDel(meetingCode) {
      const response = await delLive(meetingCode)
      if (response.code === 200) {
        await this.getLiveList();
        this.$message.success("删除成功！")
      } else {
        this.$message.warning(response.message)
      }
    },
    catalogueCancel(){
      this.titleDrawer = '';
      this.statusDrawer = '';
      this.pageNoModel = 1;
      this.catalogueVisible = false;
    },
    catalogueOk(){

    },
    seachDrawer(){
      this.pageNoModel = 1;
      this.getRooms(this.roomId);
    },
    drawerClick(val){
      if(val === '0'){
        if(this.drawer0 === 1){
          this.statusDrawer = val;
          this.drawer0++;
        }else {
          this.statusDrawer = '';
          this.drawer0 = 1;
        }
        this.drawer1 = 1;
        this.drawer2 = 1;
      }else if(val === '1'){
        if(this.drawer1 === 1){
          this.statusDrawer = val;
          this.drawer1++;
        }else {
          this.statusDrawer = '';
          this.drawer1 = 1;
        }
        this.drawer0 = 1;
        this.drawer2 = 1;
      }else if(val === '2'){
        if(this.drawer2 === 1){
          this.statusDrawer = val;
          this.drawer2++;
        }else {
          this.statusDrawer = '';
          this.drawer2 = 1;
        }
        this.drawer1 = 1;
        this.drawer0 = 1;
      }
      this.pageNoModel = 1;
      this.getRooms(this.roomId);
    },
    resetDrawer(){
      this.titleDrawer = '';
      this.statusDrawer = '';
      this.pageNoModel = 1;
      this.getRooms(this.roomId);
    },
    pageChange(pageNo,pageSize){
      this.pageNoModel = pageNo;
      this.getRooms(this.roomId);
    },
    openCatalogue(meetingCode){
      this.roomId = meetingCode;
      this.getRooms(meetingCode);
      this.catalogueVisible = true;
    },
    //获取直播间列表
    async getRooms(meetingCode) {
      let data = {
        title:this.titleDrawer,
        page_no:this.pageNoModel,
        page_size:this.pageSizeModel,
        streamStatus:this.statusDrawer
      }
      const response = await getLiveRoom(meetingCode,data)
      if(response.code === 0){
       this.drawerList = response.data.rows;
       this.totalModel = response.data.count
      }
    },
    async upOpening() {
      let inputDOM = this.$refs.upOpeningRef.files[0];
      //判断图片大小
      if (inputDOM.size < 1048576) {
        const image = await update(inputDOM)
        if (image.code === 0) {
          this.relayImg = image.data.url
          this.$message.success("上传成功")
        } else {
          this.$message.warning("上传失败")
        }

      } else {
        this.$message.warning("请上传小于1M的图片！")
      }
      document.getElementById('upOpeningId').value = null
    },
    opening_Ok(){
      this.$refs.promotion_ruleForm.validate(valid => {
        if (valid) {
          let form = this.promotion_form;
          let data = {
            "meetingCode":this.listMeetCode,//会议的code值
            "isBeginImage":form.isBeginImage, //1开启  0关闭
            "beginImageDetail":[form.beginImageDetail],
            "beginImageTime":form.beginImageTime//时长
          }
          this.putStreamFuncs(data)
        } else {
          return false;
        }
      });
    },
    // 修改直播间设置功能列表
    async putStreamFuncs(data) {
      const response = await updMeetingBeginImg(data)
      if(response.code === 200){
        this.$message.success("修改成功！");
        this.opening_visible = false;
        this.promotion_form = {};
        await this.getLiveList();
      }else {
        this.$message.warning(response.message);
      }
    },
    delCover(){
      this.relayImg = '';
    },
    switchChange(isOpen){
      this.promotion_form.isBeginImage = isOpen;
      // let data = {
      //   "meetingCode":this.meetingCode,//会议的code值
      //   "isBeginImage":isOpen ? 1:0, //1开启  0关闭
      // }
      // this.putStreamFuncs(data);
    },
    openImgModel(type){
      this.visible = true;
      this.openModelTypr = type;
    },
    closeMain(val) {
      this.visible = val;
    },
    checkList(val) {
      this.picList = val[0].url;
      if(this.openModelTypr === 'beginImageDetail'){
        this.promotion_form.beginImageDetail = this.picList;
      }else if(this.openModelTypr === 'relayImg'){
        this.add_Form.relayImg = this.picList;
      }else if(this.openModelTypr === 'homeCoverImage'){
        this.add_Form.homeCoverImage = this.picList;
      }
    },
  }
}

</script>

<style scoped lang="scss">
.liBackground {
  background: #1890ff !important;
  color: white;
}
.CCE{
  min-height: 60px;
  color: $topTitleColor;
  padding-top: 15px;
  width: $space_div;
  margin: auto;
  border-bottom: 0.5px dashed #d7d7d7;
  display: flex;
}
.live-top{
  height: 75px;
  width: 100%;
  border-bottom: 10px solid #EDEFF2;
  padding-top: 15px;
.live-top-input{
  //width: 25%;
  display: flex;
  height: 35px;
  line-height: 35px;
  //padding-left: 30px;
.input{
  width: 200px;
  //margin-left: 5px;
}
}
}
.addBtn{
  height: 50px;
  line-height: 50px;
  border: 1px solid #EDEFF2;
  margin-bottom: 15px;
  .titleAdd{
    width: $space_div;
    margin: auto;
    display: flex;
    justify-content: space-between;
    .title{
      font-weight: 600;
      color: $topNameColor;
    }
  }
}
.topCard{
  width: 98%;
  margin: auto;
  border: 1px solid #eceaea;
  border-radius: 10px;
  padding-top: 30px;
  margin-bottom: 30px;
  box-shadow:0 0 5px 2px rgb(0,0,0,0.1);
  .add{
    height: 353px;margin-left: 1%;font-size: 70px;color: #929292;text-align: center;line-height: 330px;
  }
  .card{
    width: 95%;
    margin: auto;
    border:1px solid #e5e3e3;
    .tagImg{
      height: 208px;
      border-bottom: 1px solid #F5F5F5;
      .tag{
        margin: 10px;
        color: white;
      }
      .img{
        width: 100%;height: 100%;
      }
    }
    .titleSty{
      font-weight: 600;
      width: 100%;
      height: 50px;
      display: -webkit-box;
      text-overflow: ellipsis;
      overflow: hidden;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      word-break: break-all;
    }
    .btn{
      background-color: #F7F9FA;
      color: #b4b3b3;
      height: 50px;
      line-height: 50px;
      display: flex;
      cursor: pointer;
      justify-content: space-around;
      .fontSty:hover{
        color: #45A5E6;
      }
    }
  }
  .btns{
    margin-left: 10px;
    color: #45A5E6;
    cursor: pointer;
  }
}
.live-top-drawer{
  height: 80px;
  width: 940px;margin: auto;
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  .live-top-input{
    display: flex;
    height: 35px;
    line-height: 35px;
    //padding-left: 30px;
    .input{
      width: 200px;
      margin-left: 5px;
    }
  }
}
.card{
  width: 96%;
  margin: auto;
  border:1px solid #e5e3e3;
  .tagImg{
    height: 208px;
    border-bottom: 1px solid #F5F5F5;
    .tag{
      margin: 10px;
      color: white;
    }
    .img{
      width: 100%;height: 100%;
    }
  }
  .titleSty{
    font-weight: 600;
    width: 100%;
    height: 50px;
    display: -webkit-box;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    word-break: break-all;
  }
  .btn{
    background-color: #F7F9FA;
    color: #b4b3b3;
    height: 50px;
    line-height: 50px;
    display: flex;
    cursor: pointer;
    justify-content: space-around;
    .fontSty:hover{
      color: #45A5E6;
    }
  }
}
.iconStY{
  color:#45a5e6;
  font-size: 20px;
  margin-left: 5px;
  margin-top:3px;float: left;
}
.qi{
  background-color: #F5F5F5;
  width: 40px;
  height: 32px;
  line-height: 32px;
  border: 1px solid #dbdada;
  text-align: center;
  border-left: none;
}
.qiBtn{
  background-color: #F5F5F5;
  border-radius: 0;
  border-left: none;
  position: relative;
  top: -1px;
}
.surfacePlot{
  border: #DDDDDD 2px dashed;
  width: 213px;
  height: 120px;
  border-radius: 5px;
  text-align: center;
  padding-top: 30px;
}
.uploadImg_img{
  position: absolute;
  top: 0;
  width: 100%;height:100%;
}
.uploadImg{
  border: #DDDDDD 2px dashed;
  width: 300px;
  height: 169px;
  border-radius: 5px;
  text-align: center;
  padding-top: 30px;
}
.bgImg{
  border: #DDDDDD 2px dashed;
  width: 213px;
  min-height: 213px;
  border-radius: 5px;
  text-align: center;
  padding-top: 30px;
}
.upImgModel{
  //display: flex;
  width: 320px;
  min-height: 200px;
  text-align: center;
  background-color: #f5f5f5;
  border: #DDDDDD 2px dashed;
  border-radius: 10px;
  .content{
    font-size: 20px;
    text-align: center;
    width: 100%;
    margin-top: 60px;
    color: #929292;
    .addModel{
      font-size: 30px;
    }
  }
}
.icon{
  position: absolute;
  color: red;
  right: 8px;
  top:8px;
  z-index: 99999999999999;
}
</style>
